<template>
  <div class="about">
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col :cols="12" :md="7">
            <h1 class="">How it all started?</h1>
            <p class="p-desc">Gradbee is the brainchild of three amazing IIT Bombay graduates Shivji, Siddharth and Prasenjeet, who faced a very similar problem while they were pursuing their degree. Hence they came up with this unique platform which gives students an opportunity to freelance and work part-time while still pursuing their respective degrees. The platform provides startups, NGO’s and individuals with required support on workforce without spending a lot and add quality assistance to their teams by getting support from the stars of tomorrow.</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col :cols="12" :md="7">
            <h1 class="">Our Vision</h1>
            <p class="p-desc">The aim of Gradbee is to bring in work and study culture in country like India where educational expenses are increasing at paramount rates. Gradbee envisions student’s participation in industries, to help these industries with their learnings and creativity, thus assisting industries and adding value to themselves.</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col :cols="12" :md="7">
            <h1 class="">Our Mission</h1>
            <p class="p-desc">Gradbee’s initiative is to help students and industries equally and bring in quintessential change into the student-companies ecosystem. Gradbee is a platform for students to use their skills, earn money, have flexibility to take projects, diversify their resume. Similarly Gradbee helps industries by engaging with quality students who bring in creativity, learnings, enthusiasm and a long term employee upon graduation.</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col :cols="12" :md="7">
            <h1 class="">Our Strength</h1>
            <h3>STUDENTS</h3>
            <p class="p-desc">We strive for students. Our organization vision and future plans are focused in and around helping students.</p>
              <h3>DESIGN</h3>
            <p class="p-desc">  We run on a philosophy of ‘What looks good, is good’ hence our focus is always on making GradBee more appealing.</p>
              <h3>DATA</h3>
            <p class="p-desc">The enriched data that we possess, helps us to understand our students better and in turn helps us to bring better opportunities catering the students.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'About Gradbee Inc.',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Gradbee helps students with a unique opportunity to find internships, freelance or work part-time, while still pursuing their respective degrees.'
      }]
    }
  }
}
</script>

<style scoped lang="scss">
  .p-desc {
    font-size: 1.25rem;
    line-height: 2rem;
  }
</style>
